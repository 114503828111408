import { isNumber } from "lodash"

export function makeFilters(dataConfigFilters?, quickFilter?) {
    const filters = {}
    for (const filter in dataConfigFilters) {
        if (dataConfigFilters[filter]) {
            const filterSpecs = dataConfigFilters[filter]!
            for (const filterSpec of filterSpecs) {

                if (typeof filterSpec === "object") {
                    const type = (filterSpec.type ?? 'EQUALS').toUpperCase();

                    if (!filters[filter]) filters[filter] = {}


                    if (type === 'RANGE') {
                        if (filterSpec.value) {
                            const from = filterSpec.value[0]
                            const to = filterSpec.value[1]
                            const stFrom = toFilterStringValue(from)
                            const stTo = toFilterStringValue(to)
                            filters[filter]["gte"] = stFrom
                            filters[filter]["lte"] = stTo
                        }

                        if (filterSpec.hasOwnProperty("min") && filterSpec.hasOwnProperty("max")) {
                            const from = filterSpec.min
                            const to = filterSpec.max
                            filters[filter]["gte"] = from
                            filters[filter]["lte"] = to
                        }

                        break;
                        // TODO send a range instead of gte and lte ??
                    }

                    filters[filter][type] = filterSpec.value
                }


                if (typeof filterSpec === 'string' || typeof filterSpec === 'boolean' || isNumber(filterSpec)) {

                    if (filters.hasOwnProperty(filter) &&  !Array.isArray(filters[filter])) {
                        filters[filter] = [filterSpec, filters[filter]]
                        continue;
                    }

                    if (Array.isArray(filters[filter])) {
                        filters[filter].push(filterSpec)
                        continue
                    }
                    
                    // base case single filter.. or firts of list of values
                    filters[filter] = filterSpec
                }
            }
        }
    }
    if (quickFilter) {
        filters["quickFilter"] = quickFilter.value;
        if (quickFilter.fields)
            filters["quickFilterFields"] = quickFilter.fields
    }
    return filters;
}


export function toFilterStringValue(value: any) {
    if (value && value["toISOString"]) {
        return value.toISOString();
    }

    if (value instanceof Date) {
        return value.toISOString()
    }

    return value;
}