import { useGoogleLogout } from "react-google-login";
import { useHistory } from "react-router-dom";
import AuthService from "../../services/auth.service";

import React from 'react';

export const Logout = (prop) => {

    const history = useHistory();

    if (AuthService.isLoggedWithGoogle() ) {
        const { signOut } = useGoogleLogout({
            clientId: prop.authentication?.google.clientId,
            onLogoutSuccess: () => { AuthService.logout(); history.push("/login") }
        })

        signOut();
        return  <div>...logging out</div>
    }

    return <div>Unable to logout</div>
}
