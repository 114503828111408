import Axios from "axios";
import { BehaviorSubject } from "rxjs";
import AuthService from "./auth.service";
import { RecordsByURLCache, CacheManager, SummaryRespEntities, SummaryResp } from "./cacheManager.service";
import { GeminiSchema, recordUtils } from "@gemini-projects/gemini-react-entity-lib"
import * as _ from "lodash";
import { getDataURI, DEFAULT_NAMESPACE_URI, getNamespaceURI, getEntityURI, getNamespaceEntityURI, getCountURI } from "./uriUtils";
import qs from 'qs'
import { createContext } from "react";


const getCacheKey = async (req: any) => {
    const dataUri = await getDataURI(req);
    if (!req.filter)
        return dataUri;

    return dataUri + "_" + JSON.stringify(req.filter);
}

export interface RecordRequest {
    entity: string
    lk: string
    namespace?: string
}

export interface SearchRequest {
    namespace?: string
    entity: string
    filter: {
        [field: string]: any
    }
}

export interface ObservableData<T> {
    data?: T
    state: 'LOADING' | 'CACHED' | 'LOADED' | 'NOT_FOUND'
    lastUpdate?: Date | undefined
    checking?: boolean
    lastCheck?: Date
}

// TODO specialize Observable Generic ?
export type ObservableRecord = ObservableData<any>
export type ObservableArrayRecords = ObservableData<any[]>
export type ObservableEntity = ObservableData<any> 



class ObservablesClass {
    private readonly records = new Map<string, BehaviorSubject<ObservableRecord>>();
    private readonly allRecords = new Map<string, BehaviorSubject<ObservableArrayRecords>>();
    private readonly entities = new Map<string, BehaviorSubject<ObservableEntity>>();
    private readonly searchRecords = new Map<string, BehaviorSubject<ObservableArrayRecords>>();


    static recordKey = ({ namespace, entity, lk }: { entity: string, lk: string, namespace?: string }) => (JSON.stringify({ namespace, entity, lk }));
    static allRecordsKey = ({ namespace, entity }: { entity: string, namespace?: string }) => (JSON.stringify({ namespace, entity }));
    static searchKey = ({ namespace, entity, filter }: SearchRequest) => (JSON.stringify({ namespace, entity, filter }))

    getEntity = (obj: any) => this.entities.get(ObservablesClass.allRecordsKey(obj))
    getRecord = (obj: any) => this.records.get(ObservablesClass.recordKey(obj))
    getALLRecords = (obj: any) => this.allRecords.get(ObservablesClass.allRecordsKey(obj))
    getSearchRecords = (obj: any) => this.searchRecords.get(ObservablesClass.searchKey(obj))

    newEntity = (obj: any) => {
        const targetObservable = new BehaviorSubject({ state: "LOADING", checking: true } as ObservableEntity)
        this.entities.set(ObservablesClass.allRecordsKey(obj), targetObservable)
        this.checkCachedObservables(obj);
        return targetObservable;
    }

    newRecord = (obj: any) => {
        const targetObservable = new BehaviorSubject({ state: "LOADING", checking: true } as ObservableRecord)
        this.records.set(ObservablesClass.recordKey(obj), targetObservable)
        this.checkCachedObservables(obj);
        return targetObservable;
    }

    newALLRecords = (obj: any) => {
        const targetObservable = new BehaviorSubject({ state: "LOADING", checking: true } as ObservableArrayRecords)
        this.allRecords.set(ObservablesClass.allRecordsKey(obj), targetObservable)
        this.checkCachedObservables(obj);
        return targetObservable;
    }

    newSearchRecords = (obj: any) => {
        const targetObservable = new BehaviorSubject({ state: "LOADING", checking: true } as ObservableArrayRecords)
        this.searchRecords.set(ObservablesClass.searchKey(obj), targetObservable)
        this.checkCachedObservables(obj);
        return targetObservable;
    }


    private async innerCheckData(namespace: string | undefined, entities: SummaryRespEntities, observableMap: Map<string, BehaviorSubject<any>>) {
        for (let key of Array.from(observableMap.keys())) {
            const observableKey = JSON.parse(key)
            const targetObservable = observableMap.get(key)!;
            if ((!observableKey.namespace && (!namespace || namespace === "DEFAULT")) || (observableKey.namespace === namespace)) {
                const targetUpdateValues = entities && entities[observableKey.entity.toUpperCase()]
                let updated = false;

                if (targetUpdateValues) {

                    const cacheKey = await getCacheKey(observableKey);
                    const cacheContent = await RecordsByURLCache.getWithTime(cacheKey);

                    const targetTime = Math.max(targetUpdateValues.lastCreateTimeUnix, targetUpdateValues.lastUpdateTimeUnix, targetUpdateValues.lastDeleteTimeUnix);

                    if (cacheContent && cacheContent.date < new Date(targetTime)) {
                        const newContent = await getFromURI(cacheKey);
                        targetObservable.next({ ...newContent, checking: false, lastCheck: new Date() });
                        updated = true;
                    }
                }

                if (!updated) {
                    targetObservable.next({ ...targetObservable.getValue(), checking: false, lastCheck: new Date() })
                }
            }
        }
    }

    private async innerCheckSearchData(namespace: string | undefined, entities: SummaryRespEntities, observableMap: Map<string, BehaviorSubject<any>>) {
        for (let key of Array.from(observableMap.keys())) {
            const observableKey = JSON.parse(key)
            const targetObservable = observableMap.get(key)!;

            if ((!observableKey.namespace && (!namespace || namespace === "DEFAULT")) || (observableKey.namespace === namespace)) {
                const targetUpdateValues = entities && entities[observableKey.entity.toUpperCase()]
                let updated = false;
                if (targetUpdateValues) {

                    const cacheKey = await getCacheKey(observableKey);
                    const cacheContent = await RecordsByURLCache.getWithTime(cacheKey);

                    const targetTime = Math.max(targetUpdateValues.lastCreateTimeUnix, targetUpdateValues.lastUpdateTimeUnix, targetUpdateValues.lastDeleteTimeUnix);

                    if (cacheContent && cacheContent.date < new Date(targetTime)) {
                        const newContent = await getFromSearch(observableKey);
                        targetObservable.next({ ...newContent, checking: false, lastCheck: new Date() });
                        updated = true;
                    }
                }

                if (!updated) {
                    targetObservable.next({ ...targetObservable.getValue(), checking: false, lastCheck: new Date() })
                }
            }
        }
    }

    private async innerCheckEntityMeta(namespace: string | undefined, summaryResp: SummaryResp["data"], observableMap: Map<string, BehaviorSubject<any>>) {
       for (let key of Array.from(observableMap.keys())) {
            const observableKey = JSON.parse(key)
            const targetObservable = observableMap.get(key)!;
            
            let updated = false;
            if ((!observableKey.namespace && (!namespace || namespace === "DEFAULT")) || (observableKey.namespace === namespace)) {

                const cacheKey = await getEntityURI(observableKey);
                const cacheContent = await RecordsByURLCache.get(cacheKey);
                if (cacheContent && cacheContent.data &&
                    (cacheContent.data.schema?.hash !== summaryResp.schema?.hash || cacheContent.data.restConfig?.hash !== summaryResp.restConfig?.hash )) {
                    const newContent = await getFromURI(cacheKey);
                    targetObservable.next({ ...newContent, checking: false, lastCheck: new Date() });
                    updated = true;
                } 
            } 

            if (!updated) {
                targetObservable.next({ ...targetObservable.getValue(), checking: false, lastCheck: new Date() })
            }
            
        } 
    }


    async nameSpaceCheck(namespace: { name: string, uri: string }) {
        const dataResult = await CacheManager.getNamespaceSummary(namespace);
        const entities = dataResult.entities;
        await this.innerCheckData(namespace.name, entities, this.records);
        await this.innerCheckData(namespace.name, entities, this.allRecords);
        await this.innerCheckSearchData(namespace.name, entities, this.searchRecords)
        await this.innerCheckEntityMeta(namespace.name, dataResult, this.entities)
    }


    private readonly remoteCachingSatus: { [key: string]: boolean } = {};

    async checkCachedObservables(req: { namespace?: string }) {

        if (AuthService.isLogged()) {
            const checkingNsKey = req.namespace ? req.namespace : 'DEFAULT';
            const isChecking = this.remoteCachingSatus[checkingNsKey] ?? false;

            // if there is already a checking ignore the function and let the other one to execute
            if (isChecking)
                return;

            this.remoteCachingSatus[checkingNsKey] = true;
            const namespaceURI = checkingNsKey === 'DEFAULT' ? DEFAULT_NAMESPACE_URI : await getNamespaceURI(checkingNsKey);
            await this.nameSpaceCheck({ name: checkingNsKey, uri: namespaceURI! })
            this.remoteCachingSatus[checkingNsKey] = false;
        }
    }

}
const Observables = new ObservablesClass();

async function getFromURI(uri: string) {
    try {
        const accessToken = AuthService.getAccessToken()
        let httpConfig = { headers: { 'Authorization': 'Bearer ' + accessToken } }
        const resp = await Axios.get(uri, httpConfig)
        const state = 'LOADED';
        const data = resp.data;
        RecordsByURLCache.set(uri, data)
        const meta = data.meta;
        const lastUpdate = meta?.lastUpdateTimeUnix ? new Date(meta.lastUpdateTimeUnix) : undefined;
        return { data: data.data, state, lastUpdate } as ObservableData<any>
    } catch (error: any) {
        console.error(error)
        if (error.response && error.response.status === 404)
            return { data: undefined, state: 'NOT_FOUND' } as ObservableData<any>
        else
            throw error;
    }
}

async function getFromSearch(searchReq: SearchRequest) {
    try {
        const accessToken = AuthService.getAccessToken()
        let httpConfig = { headers: { 'Authorization': 'Bearer ' + accessToken }, params: searchReq.filter }

        const dataUri = await getDataURI(searchReq);
        const cacheKey = dataUri + "_" + JSON.stringify(searchReq.filter);

        const resp = await Axios.get(dataUri, httpConfig)
        const state = 'LOADED';
        const data = resp.data;
        RecordsByURLCache.set(cacheKey, data)
        const meta = data.meta;
        const lastUpdate = meta?.lastUpdateTimeUnix ? new Date(meta.lastUpdateTimeUnix) : undefined;
        return { data: data.data, state, lastUpdate }
    } catch (error: any) {
        console.error(error)
        if (error.response && error.response.status === 404)
            return { data: undefined, state: "NOT_FOUND" } as ObservableData<any>
        else
            throw error;
    }
}

interface EntityMangerGetOptions {
    useCache?: boolean
    forceCache?: boolean
    cacheCheckTollerance?: number
}

class EntityManagerClass {

    async getEntities(namespace: string): Promise<{[key:string]: {schema: {value: any}, restConfig: {value: any}}}> {
        const accessToken = AuthService.getAccessToken()
        let httpConfig = { headers: { 'Authorization': 'Bearer ' + accessToken } }
        const namespaceEntityURI = await getNamespaceEntityURI(namespace);
        const resp = await Axios.get(namespaceEntityURI, httpConfig)
        return resp.data.data;
    }

    async newRecord(req: {
        namespace?: string
        entity: string
        record: any
    }) {
        const accessToken = AuthService.getAccessToken()
        let httpConfig = { headers: { 'Authorization': 'Bearer ' + accessToken } }
        const dataUri = await getDataURI(req);
        const resp = await Axios.post(dataUri, { data: req.record }, httpConfig)
        const respData = resp.data;


        // record response
        const state = 'LOADED';
        const data = respData.data;
        const meta = respData.meta;
        const lastUpdate = meta?.lastUpdateTimeUnix ? new Date(meta.lastUpdateTimeUnix) : undefined;

        const res: ObservableRecord = { data, state, lastUpdate };



        // try to update the cache for the ALL records cache
        const rootEntityKey = { namespace: req.namespace, entity: req.entity };
        getDataURI(rootEntityKey).then(async (rootUri) => {
            const cache = await RecordsByURLCache.get(rootUri)
            if (cache && Array.isArray(cache.data)) {
                cache.data = [...cache.data, data]
                RecordsByURLCache.updateOnlyCacheValue(rootUri, cache)
            }
            Observables.getALLRecords(rootEntityKey)?.next({ data: cache.data, state, lastUpdate })
        })

        return resp.data.data;
    }

    async update(req: {
        namespace?: string
        entity: string
        lk: string
        record: any
    }) {
        const accessToken = AuthService.getAccessToken()
        let httpConfig = { headers: { 'Authorization': 'Bearer ' + accessToken } }
        const dataUri = await getDataURI(req);
        const resp = await Axios.put(dataUri, { data: req.record }, httpConfig)
        const respData = resp.data;

        // update the record cache if exist
        RecordsByURLCache.setIfExist(dataUri, respData);

        // record response
        const state = 'LOADED';
        const data = respData.data;
        const meta = respData.meta;
        const lastUpdate = meta?.lastUpdateTimeUnix ? new Date(meta.lastUpdateTimeUnix) : undefined;

        const res: ObservableRecord = { data, state, lastUpdate };
        // update the record observable
        const observable = Observables.getRecord(req);
        observable?.next(res)

        // try to update the cache for the ALL records cache
        const rootEntityKey = { namespace: req.namespace, entity: req.entity };
        getDataURI(rootEntityKey).then(async (rootUri) => {
            const cache = await RecordsByURLCache.get(rootUri)
            const schema = await this.getSchema(rootEntityKey)
            if (cache && Array.isArray(cache.data)) {
                const toSearchLK = req.lk;

                for (let i = 0; i < cache.data.length; i++) {
                    const rec = cache.data[i]
                    if (schema && recordUtils.getRecordLk(rec, schema) === toSearchLK) {
                        cache.data[i] = data
                        RecordsByURLCache.updateOnlyCacheValue(rootUri, cache)
                    }
                }
            }
            Observables.getALLRecords(rootEntityKey)?.next({ data: cache.data, state, lastUpdate })
        })

        return res;
    }

    async delete(req: any) {
        const accessToken = AuthService.getAccessToken()
        let httpConfig = { headers: { 'Authorization': 'Bearer ' + accessToken } }
        const dataUri = await getDataURI(req);
        const resp = await Axios.delete(dataUri, httpConfig)
        // cacheManager.clean(dataUri)
        // TODO operation with meta
        const respData = resp.data;

        // record response
        const state = 'NOT_FOUND';
        const data = respData.data;
        const meta = respData.meta;
        const lastUpdate = meta?.lastUpdateTimeUnix ? new Date(meta.lastUpdateTimeUnix) : undefined;

        const res: ObservableRecord = { data, state, lastUpdate };
        // update the record observable
        const observable = Observables.getRecord(req);
        observable?.next(res)

        // try to update the cache for the ALL records cache
        const rootEntityKey = { namespace: req.namespace, entity: req.entity };
        getDataURI(rootEntityKey).then(async (rootUri) => {
            const cache = await RecordsByURLCache.get(rootUri)
            const schema = await this.getSchema(rootEntityKey)
            if (cache && Array.isArray(cache.data)) {
                const toSearchLK = req.lk;

                for (let i = 0; i < cache.data.length; i++) {
                    const rec = cache.data[i]
                    if (schema && recordUtils.getRecordLk(rec, schema) === toSearchLK) {
                        cache.data.splice(i, 1)
                        RecordsByURLCache.updateOnlyCacheValue(rootUri, cache)
                    }
                }
            }
            Observables.getALLRecords(rootEntityKey)?.next({ data: cache.data, state, lastUpdate })
        })

        return resp.data.data;
    }

    getObservableEntity(rec: { entity: string, namespace?: string }): BehaviorSubject<ObservableEntity> {
        let observable = Observables.getEntity(rec);

        if (!observable) {
            observable = Observables.newEntity(rec);
            this.getObservableEntity_Async(rec, observable);
        }
        return observable;
    }

    private async getObservableEntity_Async(entityReq: any, observable: BehaviorSubject<ObservableEntity>) {
        const entityURI = await getEntityURI(entityReq);
        let cacheData = await CacheManager.getFromCache(entityURI);
        let observableData = cacheData ? cacheData : await getFromURI(entityURI)
        observable.next({ ...observable.getValue(), ...observableData } as ObservableEntity)
    }

    getObservableRecord(rec: { entity: string, lk: string, namespace?: string }): BehaviorSubject<ObservableRecord> {
        let observable = Observables.getRecord(rec);

        if (!observable) {
            observable = Observables.newRecord(rec);
            this.getObservableRecord_Async(rec, observable);
        }
        return observable;
    }

    async getRecord(rec: { entity: string, lk: string, namespace?: string }) {
        const dataUri = await getDataURI(rec);
        return await getFromURI(dataUri)
    }

    private async getObservableRecord_Async(rec: RecordRequest, observable?: BehaviorSubject<ObservableRecord>): Promise<any> {
        const dataUri = await getDataURI(rec);
        let cacheData = await CacheManager.getFromCache(dataUri)
        let observableData = cacheData ? cacheData : await getFromURI(dataUri)

        if (observable)
            observable.next({ ...observable.getValue(), ...observableData } as ObservableRecord)
        return observableData;
    }

    getALLObservableRecords(req: { entity: string, namespace?: string }): BehaviorSubject<ObservableRecord> {
        let observable = Observables.getALLRecords(req);

        if (!observable) {
            observable = Observables.newALLRecords(req);
            this.getALLObservableRecords_Async(req, observable);
        }
        return observable;
    }

    private async getALLObservableRecords_Async(entityReq: any, observable?: BehaviorSubject<ObservableArrayRecords>) {
        const dataUri = await getDataURI(entityReq);
        let cacheData = await CacheManager.getFromCache(dataUri)
        let observableData = cacheData ? cacheData : await getFromURI(dataUri)
        if (observable)
            observable.next({ ...observable.getValue(), ...observableData } as ObservableRecord)
        return observableData;
    }

    getObservableSearch(req: SearchRequest) {
        let observable = Observables.getSearchRecords(req);

        if (!observable) {
            observable = Observables.newSearchRecords(req);
            this.getObservableSearch_Async(req, observable);
        }
        return observable;
    }

    private async getObservableSearch_Async(searchReq: SearchRequest, observable?: BehaviorSubject<ObservableArrayRecords>): Promise<any> {
        const dataUri = await getDataURI(searchReq);
        const cacheKey = dataUri + "_" + JSON.stringify(searchReq.filter);
        let cacheData = await CacheManager.getFromCache(cacheKey)

        let observableData = cacheData ? cacheData : await getFromSearch(searchReq)

        if (observable)
            observable.next(observableData as ObservableArrayRecords)
        return observableData;
    }

    async getEntity(entityReq: any, options?: EntityMangerGetOptions): Promise<ObservableEntity> {
        const normalizedOptions = this.normalizeGETOptions(options);
        if (!normalizedOptions?.useCache) {
            return getEntityURI(entityReq).then(getFromURI);
        }

        const cachedData = await CacheManager.getEntityConfigIfUpdated(entityReq, options);
        return cachedData ? cachedData : getEntityURI(entityReq).then(getFromURI);
    }

    async getSchema(entityReq: any, options?: EntityMangerGetOptions): Promise<GeminiSchema> {
        const entity = await this.getEntity(entityReq, options);
        return entity.data?.schema?.value;
    }

    async getAll(entityReq: any, options?: EntityMangerGetOptions): Promise<ObservableData<any>> {
        const normalizedOptions = this.normalizeGETOptions(options);
        if (!normalizedOptions?.useCache) {
            return getDataURI(entityReq).then(getFromURI);
        }
        const cachedData = await CacheManager.getDataIfUpdated(entityReq, options);
        return cachedData ? cachedData : getDataURI(entityReq).then(getFromURI);
    }

    async getRecords(req: {
        namespace?: string
        entity: string
        filters?: any;
        pagination?: {  start: number, limit: number },
        orderBy?: [string]
        },
        options?: EntityMangerGetOptions) {
            
        const accessToken = AuthService.getAccessToken()
        const params: any = {...req.filters}
        if(req.pagination?.start)
            params.start = req.pagination?.start
        if(req.pagination?.limit)
            params.limit = req.pagination?.limit
        if(req.orderBy)
            params.orderBy = req.orderBy
        let httpConfig = { headers: { 'Authorization': 'Bearer ' + accessToken }, params,  paramsSerializer: this.paramSerializer }
        const dataUri = await getDataURI(req);
        const resp = await Axios.get(dataUri,  httpConfig)
        return resp
    }

    async countRecords(req: {
        namespace?: string
        entity: string
        filters?: any;
    }) {
        const accessToken = AuthService.getAccessToken()
        const params: any = {...req.filters}
        let httpConfig = { headers: { 'Authorization': 'Bearer ' + accessToken }, params, paramsSerializer: this.paramSerializer}
        const countURI = await getCountURI(req);
        const resp = await Axios.get(countURI,  httpConfig)
        return resp;
    }

    async checkUpdates(req: { namespace?: string }) {
        await Observables.checkCachedObservables(req)
        await Observables.checkCachedObservables({ namespace: 'DEFAULT' })
    }

    private normalizeGETOptions(options?: EntityMangerGetOptions) {
        return _.merge({
            useCache: true,
            cacheCheckTollerance: 60
        }, options)
    }

    private paramSerializer(p: any) {
        return qs.stringify(p, { arrayFormat: 'repeat' })
    }
}

export const NamespaceContext = createContext<string | undefined>(undefined);

export const EntityManager = new EntityManagerClass()

export default EntityManager