

import SIDEBARMENU from './SIDEBARMENU'

/*
    In this file we have Client Side configurations for common components

*/


export default {
    'SIDEBARMENU': SIDEBARMENU
}