import { useRecord } from 'hooks/records.hooks';
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './App.css';
import { Login } from './components/Auth/Login';
import { Logout } from './components/Auth/Logout';
import { Root } from './components/Root/Root';
import React, { ComponentClass } from 'react';
import { EntityViewConfig } from '@gemini-projects/gemini-react-entity-lib/dist/components/common/types';


export interface GeminiAppProps {
  defaultNamespace: string
  authentication?: {
    google?: {
      clientId: string
      enabled: boolean
    }
  }
  recordsActions?: (props: { namespace: string, entity: string }) => {
    strategy: 'SUBSTITUTE' | 'APPEND',
    actions: any[]
  }
  entityActions?: (props: { namespace: string, entity: string }) => {
    strategy: 'SUBSTITUTE' | 'APPEND',
    actions: any[]
  }
  entityCallbacks?: (props: { namespace: string, entity: string }) => {
    onRecordView?: (record) => void
  }
  customComponents?: { [key: string]: { displayName: string, component: React.Component } }
  customRoutes?: {route: string, exact: boolean, fullScreen?: boolean, component: React.Component}[]
  clientSideMenu?: [ClientSideMenuItem]
  clientSideEntityViewConfig?: (params: { namespace: string, entity: string }) =>  EntityViewConfig
}

export interface ClientSideMenuItem {
  enabled: true
  id: string
  menuIcon?: string
  menuType: "GROUP" | "ENTITY" | "CUSTOM_COMPONENT" | "LABEL"
  routePath?: string
  sortkey: number
  title: string
  children?: [ClientSideMenuItem]
  customComponentConfig?: {
    component: string
  }
}


export const GeminiApp = (props: GeminiAppProps) => {

  const [settings] = useRecord({
    entity: 'BASICSETTINGS',
    lk: 'default'
  })

  return (
    <BrowserRouter>
      <div className="gemini-react-application">

        {settings.data ? (<Helmet>
          <title>{settings.data.appName}</title>
          <link rel="icon" type="image/x-icon" href={settings.data.logo} sizes="16x16" />

        </Helmet>) : null}

        {/*TODO add react-error-boundary here ??*/}

        <Switch>
          <Route exact path="/login" render={() => <Login settings={settings.data} {...props}></Login>} />
          <Route exact path="/logout" render={(rprop) => <Logout {...rprop} {...props} />} />
          {/*props.customRoutes?.filter(r => r.fullScreen).map(r => ( <Route exact={r.exact} path={r.route} component={r.component as any}></Route>))*/}
          {props.customRoutes?.filter(r => r.fullScreen).map(r => {
            const Component = r.component as unknown as ComponentClass;
            return <Route exact={r.exact} path={r.route} render={(rprop) => <Component {...rprop} {...props} />} />
          } 
          )}

          <Route path="/" render={(rprop) => <Root {...rprop} {...props} />} />
        </Switch>
      </div>
    </BrowserRouter>

  );
}

export default GeminiApp
