import { useEffect, useState } from "react";
import { useGoogleLogin } from "react-google-login";


class AuthServiceClass {

    constructor() {
        this.initialized = false;
        this.logged = false;
    }

    googleSignedIn(googleUser) {
        this.logged = true;
        this.method = "google";
        this.googleUser = googleUser;
        //localStorage.setItem("__gemini_logged", true)
    }

    logout() {
        this.logged = false;
        //localStorage.removeItem("__gemini_logged")
    }

    getCurrentUser() {
        if (this.method === 'google')
            return this.googleUser.profileObj.name;
    }

    getCurrentUserAvatar() {
        if (this.method === 'google')
            return this.googleUser.profileObj.imageUrl;
    }

    isLogged() {
        return this.logged;
    }

    isLoggedWithGoogle() {
        return this.method === 'google';
    }

    getAccessToken() {
        if (this.method === 'google') {
            const GoogleAuth = window.gapi.auth2.getAuthInstance()
            const res = GoogleAuth.currentUser.get()
            const authResponse = res.getAuthResponse(true)
            if (authResponse)
                return authResponse.id_token
            else
                window.location.reload()
        }
        return null;
    }

}

export const AuthService = new AuthServiceClass();

export function useAthentication(authentication) {
    //const prev_logged = localStorage.getItem("__gemini_logged");

    //const lodingDefault = !!prev_logged && !AuthService.isLogged()
    const [isLogged, setLogged] = useState(AuthService.isLogged())
    const [isLoading, setLoading] = useState(!AuthService.isLogged())

   if (authentication?.google?.enabled) {

        useGoogleLogin({
            onSuccess: (googleUser) => {
                AuthService.googleSignedIn(googleUser)
                setLogged(true);
                setLoading(false);
            },
            onFailure: (error) => {
                setLogged(false);
                setLoading(false);
            },
            clientId: authentication.google.clientId,
            autoLoad: false,
            isSignedIn: true
        })
    }

    useEffect(() => {
        const clear = setTimeout(() => {
            if (!isLogged && isLoading)
                setLoading(false)
        }, 2000)
        return () => {clearTimeout(clear)}
    })

    return { isLoading, isLogged, accessToken: AuthService.getAccessToken() }
}

export const auth = async (p) => {
    try {
        let res = await p;
        return res;
    } catch (e) {
        if (e.response && e.response.status === 401)
            return window.location.replace("/login");
        throw e
    }
}

export default AuthService;
