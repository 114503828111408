import { Skeleton, Tabs } from 'antd';
import { NSEntityView } from 'components/NamespaceEntities/NsEntityView';
import { useAllRecord } from 'hooks/records.hooks';
import React from 'react';
import { AllEntityView } from './AllEntityView'
const { TabPane } = Tabs;


export const Settings = () => {
  const [recordData] = useAllRecord({entity: "SETTINGSMENU"})


  if (!recordData.data)
    return <Skeleton active={true}/>

  /* 
    TODO
    if (error) {
    console.error(error)
    throw error;
  } */

  recordData.data.sort(function (a, b) {
    return a.sortkey - b.sortkey
  })

  const tabsitems = recordData.data.map((menu) => {

    if (menu.menuType === "ENTITY" && menu.entityConfig?.entity) {
      return <TabPane tab={menu.title} key={menu.id}>
        {menu.menuType === "ENTITY" ? <NSEntityView entity={menu.entityConfig?.entity}></NSEntityView> : null}
      </TabPane>
    }
    // TODO here
    return null;
  })

  return <Tabs tabPosition="left" style={{padding: 16}}>
    {tabsitems}
    <TabPane tab="All Entity View" key="allentity"><AllEntityView></AllEntityView></TabPane>
  </Tabs>

}