import { EntityView, GeminiSchema, GetStrategies, recordUtils } from '@gemini-projects/gemini-react-entity-lib';
import { EntityReferenceConfigResolver, EntityReferenceSearchResolver, EntityViewConfig, RecordOperation } from '@gemini-projects/gemini-react-entity-lib/dist/components/common/types';
import { Skeleton } from 'antd';
import { useEntity, useSearch, useRecordsCount, useRestStrategyRecords, RestConfig, useAllRecord } from 'hooks/records.hooks';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { BehaviorSubject } from 'rxjs';
import { EntityManager, ObservableEntity, ObservableArrayRecords, NamespaceContext } from 'services/entityManager.service';
import { DEFAULT_NAMESPACE } from 'services/uriUtils';
import defaultNamespaceClientConfig from './defaultNamespaceClientConfig';

const getLocalStoragePaginationLimit = (namespace, entity) => {
    const exist = localStorage.getItem("__gbo_pagination_limit_" + JSON.stringify({ namespace, entity }))
    if (exist) {
        return JSON.parse(exist);
    }
    return undefined;
}

const setLocalStoragePaginationLimit = (namespace, entity, value) => {
    localStorage.setItem("__gbo_pagination_limit_" + JSON.stringify({ namespace, entity }), JSON.stringify(value))
}

interface NsEntityViewProps {
    entity: string, lk?: string, namespace?: string,
    clientEntityViewConfig?: EntityViewConfig
    recordsActions?: {
        strategy: 'SUBSTITUTE' | 'APPEND',
        actions: (props) => any[]
    },
    entityActions?: {
        strategy: 'SUBSTITUTE' | 'APPEND',
        actions: (props) => any[]
    },
    entityCallbacks?: {
        onRecordView?: (record: any) => void
    }
}

export const NSEntityView = (props: NsEntityViewProps) => {
    const { entity, namespace, clientEntityViewConfig } = props;

    // the schema definition for the records (accordingly to Gemini types)
    const [entityData, entityReload] = useEntity(props)
    const restConfig: RestConfig = entityData.data?.restConfig?.value;

    // contain configuration to draw components for the Entity (tables/forms and so on)
    const [entityConfigData, configReload] = useSearch({
        entity: "DefaultEntityConfig",
        filter: {
            "entity": entity.toUpperCase()
        }
    })

    // get the Config
    const configRecords = entityConfigData?.data;
    let entityViewConfig: any = null;
    if (configRecords) {
        configRecords.forEach((c: any) => {
            // TODO review this (what is datastore ??)
            if ((typeof c.datastore === "string" && c.datastore.length > 0) || !entityViewConfig) {
                entityViewConfig = c;
            }
        })
    }

    let actualConfig = {}
    if (!namespace || namespace === DEFAULT_NAMESPACE) {
        const clientDefaultConfig = defaultNamespaceClientConfig[entity.toUpperCase()];
        actualConfig = _.merge(clientDefaultConfig, actualConfig)
    }
    if (clientEntityViewConfig) {
        actualConfig = _.merge(clientEntityViewConfig, actualConfig)
    }
    if (entityViewConfig && entityViewConfig.config && typeof entityViewConfig.config === "string") {
        actualConfig = _.merge(JSON.parse(entityViewConfig.config), actualConfig);
    }

    if ((!restConfig))
        return <div style={{ margin: 24 }}><Skeleton active /></div>

    const reload = () => {
        entityReload()
        configReload()
    }

    if (restConfig.getListStrategy === "START_LIMIT")
        return <NsEntityStartLimit entityData={entityData} entityConfigData={entityConfigData} reload={reload} actualConfig={actualConfig} {...props} />

    if (restConfig.getListStrategy === "ALL")
        return <NsEntityViewALL entityData={entityData} entityConfigData={entityConfigData} reload={reload} actualConfig={actualConfig} {...props} />
}

export const NsEntityStartLimit = (props:
    NsEntityViewProps & {
        entityData: ObservableEntity
        entityConfigData: ObservableArrayRecords
        actualConfig: EntityViewConfig
        reload: () => void
    }) => {
    const { namespace, entity, entityData, reload, actualConfig } = props;

    const schema: GeminiSchema = entityData.data?.schema?.value;

    /// before getting data we need the pagesize to retrieve data
    const restConfig: RestConfig = entityData.data?.restConfig?.value

    const defaultPaginationLimit = getLocalStoragePaginationLimit(namespace, entity) ?? restConfig.defaultLimit ?? 10;
    const defaultSortColumns = restConfig.defaultOrder?.map(stval => {
        if (stval.startsWith("-"))
            return {
                fieldName: stval.substring(1),
                order: "descend"
            }
        return {
            fieldName: stval,
            order: "ascend"
        }
    })
    const [tableConfig, setTableConfig] = useState<any>({ pagination: { limit: defaultPaginationLimit, page: 1 }, sortColumns: defaultSortColumns });

    const onTableChange: any = async (pagination: any, filters: any, sorters: any) => {
        const { pageSize, current } = pagination
        const gemPagination = { limit: pageSize, page: current }

        let sortColumns; // TODO handle multiple sorters ?
        if (sorters && sorters.order) {
            sortColumns = [{ fieldName: sorters.columnKey, order: sorters.order }]
        }

        setTableConfig(prev => { return { ...prev, pagination: gemPagination, filters, sortColumns } })
    }

    const debouncedHandleChange = useRef(_.debounce(function (value) {
        const qfFields = actualConfig.tableView?.quickFilter?.fields;
        setTableConfig(prev => { return { ...prev, pagination: { ...prev.pagination, page: 1 }, quickFilter: (value === "" ? undefined : { value: value, fields: qfFields }) } })
    }, 500));

    const onTableQuickFilter = (value: string) => {
        debouncedHandleChange.current(value)
        // setTableConfig(prev => { return { ...prev,  quickFilter: (value === "" ? undefined : {value: value, fields: qfFields})} })
    }

    const [countData, rr] = useRecordsCount(props, tableConfig);
    let total = countData?.data?.count;
    let tooManyRecordsWarning: any = null;
    if (total && restConfig.maxWindow && total > restConfig.maxWindow) {
        tooManyRecordsWarning = {
            total: total,
            tableWindow: restConfig.maxWindow
        }
        total = restConfig.maxWindow
    }


    const [recordData, recordDataReload] = useRestStrategyRecords(props, schema, restConfig, tableConfig)

    const reReload = () => {
        reload()
        recordDataReload()
    }

    return <FinalNSView recordData={recordData} {...props} reload={reReload} pagination={{ ...tableConfig.pagination, total }} sortColumns={tableConfig.sortColumns} tooManyRecordsWarning={tooManyRecordsWarning}
        onTableChange={onTableChange} onTableQuickFilter={onTableQuickFilter} />
}


const NsEntityViewALL = (props:
    NsEntityViewProps & {
        entityData: ObservableEntity
        entityConfigData: ObservableArrayRecords
        actualConfig: EntityViewConfig
        reload: () => void
    }) => {
    const { reload } = props;


    // contains Actual DATA and records
    const [recordData, recordDataReload] = useAllRecord(props)

    const reReload = () => {
        reload()
        recordDataReload()
    }

    return <FinalNSView recordData={recordData} {...props} reload={reReload} />
}


export const FinalNSView = (props:
    NsEntityViewProps & {
        entityData: ObservableEntity
        entityConfigData: ObservableArrayRecords
        reload: () => void
        recordData: ObservableArrayRecords
        pagination?: any
        sortColumns?: any
        onTableChange?: (pagination: any, filters: any, sorters: any) => void
        onTableQuickFilter?: (value: string) => void
        actualConfig: EntityViewConfig
        tooManyRecordsWarning?: any
    }) => {

    const { namespace, entity, recordsActions, entityActions, entityCallbacks, entityData, entityConfigData, reload, recordData, pagination, sortColumns, tooManyRecordsWarning, onTableChange, onTableQuickFilter } = props;
    const schema = entityData.data?.schema?.value;
    let { actualConfig } = props

    // contains Actual DATA and records
    const records = recordData.data;
    const recordsLoading = recordData.state == "LOADING";

    // understand and update the status of the records (may be updated, checking or old)
    const [recordsStatus, setRecordStatus] = useState<any>(undefined);

    useEffect(() => {
        if (recordData.checking !== undefined || entityData.checking !== undefined || entityConfigData.checking !== undefined) {
            if (recordData.checking || entityData.checking || entityConfigData.checking) {
                setRecordStatus("CHECKING")
            } else {
                setRecordStatus("CHECKED");
            }
        }
    }, [recordData, entityData, entityConfigData]);

    const resolverSchemaCache = useRef(new Map<string, { schema: BehaviorSubject<GeminiSchema | undefined> }>());

    const configRecords = entityConfigData?.data;
    if ((!configRecords || !schema || !records))
        return <div style={{ margin: 24 }}><Skeleton active /></div>


    const onRecordCreate: RecordOperation = async (context, ok, error) => {
        try {
            // const resp = await auth(axios.post(actionUrl, { data: newRec }, httpConfig))
            await EntityManager.newRecord({ ...props, record: context.record });
            return ok();
        } catch (e) {
            console.error(e)
            error()
        }

    }

    const onRecordDelete: RecordOperation = async (context, ok, error) => {
        try {
            await EntityManager.delete({ ...props, record: context.record, lk: context.lk });
            ok()
        } catch (e) {
            console.error(e)
            error()
        }
    }

    const onRecordUpdate: RecordOperation = async (context, ok, error) => {
        try {
            await EntityManager.update({ ...props, lk: context.lk!, record: context.record });
            ok()
        } catch (e) {
            console.error(e)
            error()
        }
    }

    const entityReferenceConfigResolver: EntityReferenceConfigResolver = async (entity: string) => {
        // TODO ADD CONFIG and CACHE

        // ref schema cache of course....
        if (!resolverSchemaCache.current.has(entity)) {
            const subject = new BehaviorSubject<GeminiSchema | undefined>(undefined);
            resolverSchemaCache.current.set(entity, { schema: subject })
            const schema = await EntityManager.getSchema({ entity, namespace });
            subject.next(schema)
        }

        return await new Promise((resolve, reject) => {
            resolverSchemaCache.current.get(entity)!.schema.subscribe((schema) => {
                if (schema) {
                    resolve({ schema })
                }
            })
        })

    }

    const entityReferenceSearchResolver: EntityReferenceSearchResolver = async (values: string, search: string, entity: string) => {
        const entityMeta = await EntityManager.getEntity({ entity, namespace });
        // TODO search and value

        if (entityMeta.data?.restConfig?.value?.getListStrategy === GetStrategies.ALL) {
            const recordsDT = await EntityManager.getAll({ entity, namespace });

            const array = recordsDT!.data ?? [] as Array<any>;
            if (values != undefined || (search === "" || search === undefined))
                return { records: array }
            if (entityMeta.data?.schema?.value) {
                const filtered = array.filter((e: any) => {
                    return recordUtils.getRecordLk(e, entityMeta.data.schema.value).toLowerCase().includes(search);
                })
                return { records: filtered }
            }
        }
        return { records: [] }
    }


    const onReload = async () => {
        reload()
        resolverSchemaCache.current.clear()
    }


    const defaultPaginationLimit = getLocalStoragePaginationLimit(namespace, entity) ?? 10;
    const onTableChange_: any = async (pagination: any, filters: any, sorters: any) => {
        const { pageSize } = pagination
        setLocalStoragePaginationLimit(namespace, entity, pageSize)
        if (onTableChange)
            onTableChange(pagination, filters, sorters)
    }

    const defaultTableSize: any = localStorage.getItem("__gbo_tablesize_" + JSON.stringify({ namespace, entity })) ?? undefined;
    const onTableSizeChange = async (size: any) => {
        localStorage.setItem("__gbo_tablesize_" + JSON.stringify({ namespace, entity }), size)
    }

    let defaultTableViewColumns: any = localStorage.getItem("__gbo_tablecheckedfields_" + JSON.stringify({ namespace, entity })) ?? undefined;
    defaultTableViewColumns = defaultTableViewColumns ? JSON.parse(defaultTableViewColumns) : defaultTableViewColumns;
    const onTableViewColumns = async (checkedFields: any) => {
        localStorage.setItem("__gbo_tablecheckedfields_" + JSON.stringify({ namespace, entity }), JSON.stringify(checkedFields))
    }

    const tablePagination: any = { showSizeChanger: true, defaultPageSize: defaultPaginationLimit }
    pagination && pagination.total && (tablePagination.total = pagination.total, tablePagination.current = pagination.page)

    // OVERRIDE (disable) Entity Operations Configurations if APIs are not allowed
    const restConfig: RestConfig = entityData.data?.restConfig?.value
    const canCreateRecord = restConfig.allowedMethods?.includes("NEW")
    const canUpdateRecord = restConfig.allowedMethods?.includes("UPDATE")
    const canDeleteRecord = restConfig.allowedMethods?.includes("DELETE")
    if (!canCreateRecord)
        actualConfig = _.set(actualConfig, 'allViews.operations.canCreate', false)
    if (!canDeleteRecord)
        _.set(actualConfig, 'allViews.operations.canDelete', false)
    if (!canUpdateRecord)
        _.set(actualConfig, 'allViews.operations.canUpdate', false)


    return <NamespaceContext.Provider value={namespace}>
        <EntityView key={JSON.stringify({ namespace, entity })} schema={schema} records={records} config={actualConfig} recordsStatus={recordsStatus} /*oldStatusConfig={oldStatusConfig}*/
            onRecordCreate={onRecordCreate} onRecordUpdate={onRecordUpdate} onRecordDelete={onRecordDelete}
            recordsLoading={recordsLoading} tableAllowedSortColumns={restConfig.allowedOrderingFields}
            onTableChange={onTableChange_} tablePagination={tablePagination} tableSortColumns={sortColumns} onTableQuickFilter={onTableQuickFilter}
            onReload={onReload} visibleStatus={['CHECKING']} onTableSizeChange={onTableSizeChange} defaultTableSize={defaultTableSize}
            entityReferenceConfigResolver={entityReferenceConfigResolver} entityReferenceSearchResolver={entityReferenceSearchResolver}
            defaultTableViewColumns={defaultTableViewColumns} onTableViewColumnsChange={onTableViewColumns}
            recordActionsStrategy={recordsActions?.strategy} recordActions={recordsActions?.actions}
            entityActionsStrategy={entityActions?.strategy} entityActions={entityActions?.actions}
            tooManyRecordsWarning={tooManyRecordsWarning}
            {...entityCallbacks}
        />
    </NamespaceContext.Provider>

}