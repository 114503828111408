import { Card, Col, Divider, Row, Skeleton, Space, Tag } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import { Link } from 'react-router-dom';

const menuAlgo = (items, level?) => {
    level = level ? level + 1 : 1;
    const sections: any = [];
    let actualFinalElem: any= []
    for (let i in items) {
        const item = items[i]

        if (item && ((process.env.NODE_ENV !== 'development' && !item.dev && item.enabled) || (process.env.NODE_ENV === 'development' && item.enabled))) {

            if (item.menuType === "ENTITY" || item.menuType === "CUSTOM_COMPONENT") {
                actualFinalElem.push(<Col key={item.id} xs={24} sm={12} md={8} lg={8} xl={4}>
                    <Link to={item.routePath}>
                        <Card hoverable={true}>
                            <Title style={{ textAlign: 'center' }} level={5}>{item.title}</Title>
                        </Card>
                    </Link>
                </Col>)
            } else {
                if (actualFinalElem.length > 0) {
                    sections.push(<Row style={{ marginBottom: 20, marginTop: 20 }} key={i} gutter={[16, 16]}>{actualFinalElem}</Row>)
                    actualFinalElem = [];
                }

                if (item.menuType === "GROUP") {

                    sections.push(
                        <div key={item.id} style={{ width: "100%", marginBottom: 10 }}>
                            <Title level={3}>
                                {item.title}
                            </Title>
                            {menuAlgo(item.children, level)}
                        </div>
                    )
                }

                if (item.menuType === "LABEL") {
                    sections.push(
                        <div key={item.id} style={{ width: "100%" }}>
                            <Divider orientation="left">
                                <Title level={5}>
                                    <Space align="center">
                                        {item.title}
                                        {item.children ? <Tag style={{ display: 'block' }}>{item.children.length}</Tag> : null}
                                    </Space>
                                </Title>
                            </Divider>
                            <div style={{ padding: "8px 0" }}>
                                {menuAlgo(item.children, level)}
                            </div>
                        </div>
                    )
                }

            }

        }
    }

    // last entity arrays
    if (actualFinalElem.length > 0) {
        sections.push(<Row key={level} gutter={[16, 16]}>{actualFinalElem}</Row>)
    }

    return sections;
}

export const BasicHome = (props) => {
    if (!props.basicSettings)
        return <Skeleton active={true}></Skeleton>

    return <div style={{padding: 24}}>
        <Title level={2}>{props.basicSettings.appName}</Title>
        {menuAlgo(props.treeMenu)}
    </div>
}