import { Card, Spin } from "antd";
import * as React from 'react';
import GoogleLogin from "react-google-login";
import { Redirect, useHistory } from "react-router-dom";
import AuthService, { useAthentication } from "../../services/auth.service";


export const Login = (prop) => {

    const history = useHistory();
    const { isLoading, isLogged } = useAthentication(prop.authentication);

    if (isLogged)
        return <Redirect to="/"></Redirect>

    const onGoogleSignIn = (googleUser) => {
        // todo robbe con user
        AuthService.googleSignedIn(googleUser)
        history.push("/")
    }

    const Title = () => {

        return <>
            {prop.settings && prop.settings.logo ? <div style={{ marginBottom: "10px" }}><img alt="logo" style={{ maxWidth: "100px" }} src={prop.settings.logo}></img></div> : null}
            {prop.settings && prop.settings.appName ? prop.settings.appName : "Login"}
        </>
    }

    return (
        <div className="centeredContainer">
            <Spin spinning={isLoading}>
                <Card title={<Title></Title>} headStyle={{ textAlign: "center" }} bodyStyle={{ textAlign: "center" }} style={{ width: 300 }}>
                    {prop.authentication?.google?.enabled ? <GoogleLogin
                        clientId={prop.authentication?.google?.clientId}
                        onSuccess={onGoogleSignIn}
                        isSignedIn={false}
                    /> : null}
                </Card>
            </Spin>
        </div>
    )
}