import { GeminiSchema } from "@gemini-projects/gemini-react-entity-lib";
import { useEffect, useState } from "react";
import { EntityManager, ObservableArrayRecords, ObservableData, ObservableRecord, ObservableEntity, SearchRequest } from "../services/entityManager.service";
import { makeFilters, toFilterStringValue } from "./utils";

export interface RestConfig {
    getListStrategy: 'ALL' | 'START_LIMIT'
    allowedOrderingFields?: string[]
    defaultLimit?: number
    maxWindow?: number
    defaultOrder?: string[]
    allowedMethods?: ['GET_LIST' | 'GET_BYID' | 'NEW' | 'UPDATE' | 'DELETE']
}

export function useRecord(req: { entity: string, lk: string, namespace?: string }): [ObservableData<any | undefined>, () => void] {

    const [retVal, setState] = useState({ state: 'LOADING' } as ObservableRecord)
    const observable = EntityManager.getObservableRecord(req);
    const reload = () => {
        observable.next({ ...observable.getValue(), checking: true });
        EntityManager.checkUpdates(req);
    }

    useEffect(() => {
        const subscription = observable.subscribe(setState)

        return function cleanup() {
            subscription?.unsubscribe();
        }
    }, [req, observable])

    return [retVal, reload]
}


export function useAllRecord(req: { entity: string, namespace?: string }): [ObservableArrayRecords, () => void] {

    const [retVal, setState] = useState({ state: 'LOADING' } as ObservableArrayRecords)
    const observable = EntityManager.getALLObservableRecords(req);
    const reload = () => {
        observable.next({ ...observable.getValue(), checking: true });
        EntityManager.checkUpdates(req);
    }

    useEffect(() => {
        const subscription = observable.subscribe(setState)

        return function cleanup() {
            subscription?.unsubscribe();
        }
    }, [req, observable])

    return [retVal, reload]
}

export function useRestStrategyRecords(
    req: { entity: string, namespace?: string },
    schema: GeminiSchema,
    restConfig?: RestConfig,
    dataConfig?: {
        quickFilter?: {value: string, fields?: string[]}
        pagination?: { limit?: number, page?: number },
        sortColumns?: [{ fieldName: string, order: "ascend" | "descend" }]
        filters?: { [key: string]: [{ type?: 'range', useTime?: boolean, value: any } | string | boolean | number] | undefined }
    }
): [ObservableArrayRecords, () => void] {

    const [retVal, setState] = useState({ state: 'LOADING' } as ObservableArrayRecords)

    const reload = () => {
        if (dataConfig) {
            setState(prev => ({...prev, state: 'LOADING' }))
            let getRequest: any = { ...req, pagination: {} }
            const limit = dataConfig?.pagination?.limit;
            limit && (getRequest.pagination.limit = limit)
            const page = dataConfig?.pagination?.page
            limit && page && (getRequest.pagination.start = limit * (page - 1))
            const orderBy = dataConfig?.sortColumns?.map(el => {
                return (el.order === "ascend" ? "+" : "-") + el.fieldName
            })
            orderBy && (getRequest.orderBy = orderBy)
            getRequest.filters = makeFilters(dataConfig?.filters, dataConfig?.quickFilter);
            EntityManager.getRecords(getRequest).then((resp: any) => {
                if (resp.data) {
                    setState({ state: 'LOADED', data: resp.data.data })
                }
            })
        }

    }

    useEffect(() => {
        reload()
    }, [dataConfig?.pagination, dataConfig?.filters, dataConfig?.quickFilter])

    return [retVal, reload]
}

export function useRecordsCount(
    req: { entity: string, namespace?: string },
    dataConfig?: {
        quickFilter?: {value: string, fields?: string[]}
        filters?: { [key: string]: [{ type?: 'range', useTime?: boolean, value: any } | string] | undefined }
    }
    ): [ObservableData<{ count?: number }>, () => void] {
    const [retVal, setState] = useState({ state: 'LOADING' } as ObservableData<{ count?: number }>)
    const reload = () => {
        setState(prev => ({...prev, state: 'LOADING' }))
        let countRequest: any = {...req}
        countRequest.filters = makeFilters(dataConfig?.filters, dataConfig?.quickFilter);
        EntityManager.countRecords(countRequest).then((resp: any) => {
            if (resp.data) {
                setState({ state: 'LOADED', data: resp.data.data })
            }
        })
    }
    useEffect(() => {
        reload()
    }, [dataConfig, dataConfig?.filters, dataConfig?.quickFilter])
    return [retVal, reload]
}

export function useEntity(req: { entity: string, namespace?: string }): [ObservableEntity, () => void] {
    const [schema, setEntity] = useState({ state: 'LOADING' } as ObservableEntity)
    const observable = EntityManager.getObservableEntity(req)
    const reload = () => {
        observable.next({ ...observable.getValue(), checking: true });
        EntityManager.checkUpdates(req);
    }

    useEffect(() => {

        const subscription = EntityManager.getObservableEntity(req).subscribe(setEntity)

        return function cleanup() {
            subscription?.unsubscribe();
        }
    }, [req]);

    return [schema, reload];
}


export function useSearch(req: SearchRequest): [ObservableArrayRecords, () => void] {
    const [retVal, setState] = useState({ state: 'LOADING' } as ObservableArrayRecords)
    const observable = EntityManager.getObservableSearch(req)
    const reload = () => {
        observable.next({ ...observable.getValue(), checking: true });
        EntityManager.checkUpdates(req);
    }
    useEffect(() => {
        const subscription = observable.subscribe(setState)

        return function cleanup() {
            subscription?.unsubscribe();
        }
    }, [req, observable])

    return [retVal, reload]
}